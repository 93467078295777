import { ForgetPasswordModelType, UserModelType } from '../../models/UserModel';
import { UserStateType, UserActionTypes } from './type';
//import { InfluencerModelType, UserModelType } from "models/UserModel";

const initialState: UserStateType = {
  token: '',
  topInfluencers: '', //[] as InfluencerModelType[],
  userData: {} as UserModelType,
  forgetEmail: {} as ForgetPasswordModelType,
  choosenStatus: '',
  choosenTag: '',
  choosenProduct: '',
};

export function userReducer(state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case 'ADD_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'ADD_TOP_INFLUENCERS':
      return {
        ...state,
        topInfluencers: action.payload,
      };
    case 'ADD_USER_DATA':
      return {
        ...state,
        userData: action.payload,
      };

    case 'ADD_FORGET_EMAIL':
      return {
        ...state,
        forgetEmail: action.payload,
      };
    case 'REMOVE_USER_DATA':
      return {
        ...state,
        userData: {},
      };
    case 'ADD_CHOOSEN_STATUS':
      return {
        ...state,
        choosenStatus: action.payload,
      };
    case 'ADD_CHOOSEN_TAG':
      return {
        ...state,
        choosenTag: action.payload,
      };
    case 'ADD_CHOOSEN_PRODUCT':
      return {
        ...state,
        choosenProduct: action.payload,
      };
    default:
      return state;
  }
}
