import { ProductActionTypes, ProductStateType } from './type';
import { CategoryProductModelType } from '../../models/ReviewModel';
import { ProductCategoryModelType } from '../../models/ProductModel';
//import { InfluencerModelType, UserModelType } from "models/UserModel";

const initialState: ProductStateType = {
  categoryProductData: {} as CategoryProductModelType,
  productDataList: {} as ProductCategoryModelType,
  productVariantType: '' as string,
  productCgId: 0 as number,
  productBuyId: 0 as number,
  productOrderCounter: 0 as number,
  productVariantId: 0 as number,
  productOrderType: 'ShopingCart' || 'BuyNow' || '',
};

export function productReducer(
  state = initialState,
  action: ProductActionTypes
) {
  switch (action.type) {
    case 'ADD_CATEGORY_PRODUCT_DATA':
      return {
        ...state,
        categoryProductData: action.payload,
      };

    case 'ADD_PRODUCT_DATA_LIST':
      return {
        ...state,
        productDataList: action.payload,
      };

    case 'ADD_PRODUCT_CG_ID':
      return {
        ...state,
        productCgId: action.payload,
      };

    //begin product list
    case 'ADD_PRODUCT_ID':
      return {
        ...state,
        productBuyId: action.payload,
      };
    case 'ADD_PRODUCT_VARIANT_TYPE':
      return {
        ...state,
        productVariantType: action.payload,
      };

    case 'ADD_PRODUCT_ORDER_COUNTER':
      return {
        ...state,
        productOrderCounter: action.payload,
      };
    case 'ADD_PRODUCT_VARIANT_ID':
      return {
        ...state,
        productVariantId: action.payload,
      };
    case 'ADD_PRODUCT_ORDER_TYPE':
      return {
        ...state,
        productOrderType: action.payload,
      };
    default:
      return state;
  }
}
