import Cookies from 'universal-cookie';

const authCheck = () => {
  const cookies = new Cookies();
  const cookie_token = process.env.REACT_APP_COOKIE_TOKEN!;
  const token = cookies.get(cookie_token);
  console.log('auth check', token);

  if (token !== undefined) {
    return true;
  }
  return false;
};

export default authCheck;
