import { UserVoucherDetailModelType } from "../../models/VoucherModel";
import { VoucherActionTypes, VoucherStateType } from "./type";

const initialState: VoucherStateType = {
    voucher: {} as UserVoucherDetailModelType,
    selectedVoucher: ''
}

export function voucherReducer(state = initialState, action: VoucherActionTypes) {
    switch (action.type) {
        case 'ADD_VOUCHER':
            return {
                ...state,
                voucher: action.payload
            }
        case 'REMOVE_VOUCHER':
            return {
                ...state,
                voucher: {},
            };
        case 'ADD_SELECTED_VOUCHER':
            return {
                ...state,
                selectedVoucher: action.payload
            }
        default:
            return state;
    }
}