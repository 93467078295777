import numeral from 'numeral';
import moment from 'moment';

const localeSettings = () => {
  numeral.register('locale', 'id', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: function(number) {
      return number === 1 ? 'st' : 'th';
    },
    currency: {
      symbol: 'Rp. ',
    },
  });

  numeral.locale('id');

  moment.updateLocale('en', {
    months: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
  });
};

export default localeSettings;
