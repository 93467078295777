import { AddressListItemModelType } from '../../models/AddressModelType';
import { PaymentMethodItemModelType } from '../../models/CheckoutModel';
import { CourierItemModelType } from '../../models/CourierModel';
import { PreviewGroupBuyCartItemModelType } from '../../models/GroupBuyModel';
import { CartActionTypes, CartStateType } from './type';

//var cart = localStorage.getItem("cart");

const initialState: CartStateType = {
  cart: 0,
  addressList: [] as AddressListItemModelType[],
  paymentItem: {} as PaymentMethodItemModelType,
  selectedAddress: '' as string,
  courierList: [] as CourierItemModelType[],
  selectedCourier: '' as string,
  deliveryPromo: '' as string,
  deliveryFee: '' as string,
  insurance: '0' as string,
  renderCheckout: true as boolean,
  tokenCart: '',
  groupCartId: 0,
  groupCgId: 0,
  cartCheckoutId: 0,
  orderId: '',
  cartData: [] as PreviewGroupBuyCartItemModelType[],
};

export function cartReducer(state = initialState, action: CartActionTypes) {
  switch (action.type) {
    case 'ADD_CART_ITEM':
      return {
        ...state,
        cart: action.payload,
      };

    case 'ADD_ADDRESS_ITEM':
      return {
        ...state,
        addressList: action.payload,
      };

    case 'ADD_PAYMENT_ITEM':
      return {
        ...state,
        paymentItem: action.payload,
      };

    case 'ADD_SELECTED_ADDRESS':
      return {
        ...state,
        selectedAddress: action.payload,
      };

    case 'ADD_COURIER_ITEM':
      return {
        ...state,
        courierList: action.payload,
      };

    case 'ADD_SELECTED_COURIER':
      return {
        ...state,
        selectedCourier: action.payload,
      };

    case 'ADD_DELIVERY_PROMO':
      return {
        ...state,
        deliveryPromo: action.payload,
      };

    case 'ADD_DELIVERY_FEE':
      return {
        ...state,
        deliveryFee: action.payload,
      };

    case 'ADD_INSURANCE':
      return {
        ...state,
        insurance: action.payload,
      };

    case 'RENDER_CHECKOUT':
      return {
        ...state,
        renderCheckout: action.payload,
      };
    case 'ADD_TOKEN':
      return {
        ...state,
        tokenCart: action.payload,
      };
    case 'GROUP_CART_ID':
      return {
        ...state,
        groupCartId: action.payload,
      };
    case 'GROUP_CG_ID':
      return {
        ...state,
        groupCgId: action.payload,
      };
    case 'ADD_ORDER_ID':
      return {
        ...state,
        orderId: action.payload,
      };
    case 'ADD_CART_DATA':
      return {
        ...state,
        cartData: action.payload
      };
    default:
      return state;
  }
}
