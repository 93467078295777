import { ComponentType, lazy, LazyExoticComponent, ReactNode } from 'react';
import Fallback from '../pages/fallback/Fallback';

export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
}

export const routes: IRoute[] = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/newsfeed/Newsfeed')),
    fallback: Fallback,
  },
  {
    path: '/video/:contentId',
    exact: true,
    component: lazy(() => import('../pages/videoDetail/VideoDetail')),
    fallback: Fallback,
  },
  {
    path: '/article/:articleUri',
    exact: true,
    component: lazy(() => import('../pages/articleDetail/ArticleDetail')),
    fallback: Fallback,
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/login/Login')),
    fallback: Fallback,
  },
  {
    path: '/signup',
    exact: true,
    component: lazy(() => import('../pages/signup/Signup')),
    fallback: Fallback,
  },
  {
    path: '/add-video',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addVideo/AddVideo')),
    fallback: Fallback,
  },
  {
    path: '/add-review',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addReview/AddReview')),
    fallback: Fallback,
  },
  {
    path: '/product',
    exact: true,
    fallback: Fallback,
  },
  {
    path: '/product/:productUri',
    exact: true,
    component: lazy(() => import('../pages/productDetail/ProductDetail')),
    fallback: Fallback,
  },
  {
    path: '/edit-profile',
    exact: true,
    component: lazy(() => import('../pages/editProfile/EditProfile')),
    fallback: Fallback,
  },
  {
    path: '/u/:userUri',
    exact: true,
    component: lazy(() => import('../pages/profile/Profile')),
    fallback: Fallback,
  },
  {
    path: '/generate-sitemap',
    exact: true,
    component: lazy(() => import('../sitemap/Sitemap')),
    fallback: Fallback,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: lazy(() =>
      import('../pages/termsAndConditions/TermsAndConditions')
    ),
    fallback: Fallback,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: lazy(() => import('../pages/privacyPolicy/PrivacyPolicy')),
    fallback: Fallback,
  },
  {
    path: '/poin',
    exact: true,
    component: lazy(() => import('../pages/points/Points')),
    private: true,
    fallback: Fallback,
  },
  {
    path: '/redeem-poin',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/redeem/Redeem')),
    fallback: Fallback,
  },
  {
    path: '/category',
    exact: true,
    component: lazy(() => import('../pages/productCategory/ProductCategory')),
    fallback: Fallback,
  },
  {
    path: '/category/:categoryId',
    exact: true,
    component: lazy(() => import('../pages/category/Category')),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng',
    exact: true,
    component: lazy(() =>
      import('../pages/groupBuyLandingPages/GroupBuyLandingPages')
    ),
    fallback: Fallback,
  },
  {
    path: '/add-product',
    exact: true,
    component: lazy(() => import('../pages/addProduct/AddProduct')),
    private: true,
    fallback: Fallback,
  },
  {
    path: '/success',
    exact: true,
    component: lazy(() => import('../pages/success/Success')),
    fallback: Fallback,
  },
  {
    path: '/hot-products/:collectionUri',
    exact: true,
    component: lazy(() => import('../pages/hotProducts/HotProducts')),
    fallback: Fallback,
  },
  {
    path: '/history',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/history/History')),
    fallback: Fallback,
  },
  {
    path: '/history/order-detail/:orderId',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/orderDetail/OrderDetail')),
    fallback: Fallback,
  },
  {
    path: '/how-to-pay',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/howToPay/HowToPay')),
    fallback: Fallback,
  },
  {
    path: '/checkout/:cartId',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/checkout/Checkout')),
    fallback: Fallback,
  },
  {
    path: '/courier/:destination/:weight/:price',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/courier/Courier')),
    fallback: Fallback,
  },
  {
    path: '/address-list',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addressList/AddressList')),
    fallback: Fallback,
  },
  {
    path: '/edit-address/:addressId',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/editAddress/EditAddress')),
    fallback: Fallback,
  },
  {
    path: '/add-address',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addAddress/AddAddress')),
    fallback: Fallback,
  },
  {
    path: '/payment-success',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/paymentSuccess/PaymentSuccess')),
    fallback: Fallback,
  },
  {
    path: '/payment-unsuccess',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/paymentUnsuccess/PaymentUnsuccess')),
    fallback: Fallback,
  },
  {
    path: '/shopping-cart',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/shoppingCart/ShoppingCart')),
    fallback: Fallback,
  },
  {
    path: '/beli',
    exact: true,
    component: lazy(() => import('../pages/home/Home')),
    fallback: Fallback,
  },
  {
    path: '/search',
    exact: true,
    component: lazy(() => import('../pages/search/Search')),
    fallback: Fallback,
  },
  {
    path: '/wishlist',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/wishlist/Wishlist')),
    fallback: Fallback,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('../pages/forgotPassword/ForgotPassword')),
    fallback: Fallback,
  },
  {
    path: '/password/reset/:token',
    exact: true,
    component: lazy(() => import('../pages/resetPassword/ResetPassword')),
    fallback: Fallback,
  },
  {
    path: '/reset-password-success',
    exact: true,
    component: lazy(() =>
      import('../pages/resetPasswordSuccess/ResetPasswordSuccess')
    ),
    fallback: Fallback,
  },
  {
    path: '/send-email',
    exact: true,
    component: lazy(() => import('../pages/sendEmail/SendEmail')),
    fallback: Fallback,
  },
  /* voucher */
  {
    path: '/voucher',
    exact: true,
    component: lazy(() =>
      import('../pages/voucher/VoucherPage')
    ),
    fallback: Fallback,
  },
  {
    path: '/voucher-subscription',
    exact: true,
    component: lazy(() =>
      import('../pages/voucherSubscription/VoucherSubscription')
    ),
    fallback: Fallback,
  },
  {
    path: '/voucher-subscription/:voucherUri',
    exact: true,
    component: lazy(() => import('../pages/voucherDetail/VoucherDetail')),
    fallback: Fallback,
  },
  {
    path: '/checkout-voucher/:cartId',
    exact: true,
    component: lazy(() => import('../pages/checkoutVoucher/CheckoutVoucher')),
    fallback: Fallback,
  },
  {
    path: '/voucher-regular',
    exact: true,
    component: lazy(() => import('../pages/regularVoucher/RegularVoucher')),
    fallback: Fallback,
  },
  {
    path: '/voucher-regular/:voucherUri',
    exact: true,
    component: lazy(() =>
      import('../pages/regularVoucherDetail/RegularVoucherDetail')
    ),

    fallback: Fallback,
  },
  {
    path: '/beli-bareng-checkout/:cgId/:cartId',
    exact: true,
    component: lazy(() => import('../pages/groupBuyCheckout/GroupBuyCheckout')),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng/:productUri',
    exact: true,
    component: lazy(() =>
      import('../pages/groupBuyProductDetail/GroupBuyProductDetail')
    ),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng/shopping-cart/:cgId',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/groupBuyShoppingCart/GroupBuyShoppingCart')
    ),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng-payment-unsuccess',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/groupBuyPaymentUnsuccess/GroupBuyPaymentUnsuccess')
    ),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng-payment-unfinished',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/groupBuyPaymentUnfinished/GroupBuyPaymentUnfinished')
    ),
    fallback: Fallback,
  },
  {
    path: '/beli-bareng-deskripsi',
    exact: true,
    component: lazy(() =>
      import('../pages/groupBuyDescription/GroupBuyDescription')
    ),
    fallback: Fallback,
  },
  {
    path: '/metode-pembayaran',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/paymentMethod/PaymentMethod')),
    fallback: Fallback,
  },
  {
    path: '/history/status/:orderId',
    exact: true,
    component: lazy(() =>
      import('../pages/orderStatusDetail/OrderStatusDetail')
    ),
    fallback: Fallback,
  },
  
  //Affiliate
  {
    path: '/affiliate-join',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/affiliate/Affiliate')),
    fallback: Fallback,
  },
  {
    path: '/affiliate',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateDashboard/AffiliateDashboard')
    ),
    fallback: Fallback,
  },
  {
    path: '/add-article',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addArticle/AddArticle')),
    fallback: Fallback,
  },
  {
    path: '/search-product',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/searchArticle/SearchArticle')),
    fallback: Fallback,
  },
  {
    path: '/affiliate/add-account',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/addAccount/AddAccount')),
    fallback: Fallback,
  },
  {
    path: '/affiliate/add-account/withdrawal-method',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/chooseAccountBank/ChooseAccountBank')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate-registration',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateRegistration/AffiliateRegistration')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate-history',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/affiliateHistory/AffiliateHistory')),
    fallback: Fallback,
  },
  {
    path: '/affiliate-help',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/affiliate/Affiliate')),
    fallback: Fallback,
  },
  {
    path: '/affiliate-help-center',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/affiliateHelp/AffiliateHelp')),
    fallback: Fallback,
  },
  {
    path: '/affiliate-help-bank',
    exact: true,
    private: false,
    component: lazy(() =>
      import('../pages/affiliateHelpBank/AffiliateHelpBank')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate/add-account/confirmation',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/addAccountConfirmation/AddAccountConfirmation')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate/add-account/success',
    exact: true,
    private: false,
    component: lazy(() =>
      import('../pages/addAccountSuccess/AddAccountSuccess')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate/withdrawal',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliiateWithdrawalBank/AffiliateWithdrawalBank')
    ),
    fallback: Fallback,
  },
  {
    path: '/list-rate-product',
    exact: true,
    private: false,
    component: lazy(() =>
      import('../pages/listRateProduct/ListRateProductPage')
    ),
    fallback: Fallback,
  },
  {
    path: '/add-rate-product',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/rateOrderProduct/RateOrderProduct')),
    fallback: Fallback,
  },
  {
    path: '/affiliate/withdrawal/confirm',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateWithdrawalConfirm/AffiliateWithdrawalConfirm')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate-request-verification',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateRequestVerification/AffiliateRequestVerification')
    ),
    fallback: Fallback,
  },
  {
    path: '/verify_email_account/:emailToken',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateVerifyEmail/AffiliateVerifyEmail')
    ),
    fallback: Fallback,
  },
  {
    path: '/verify_account/:bankId/:tokenVerify',
    exact: true,
    private: false,
    component: lazy(() =>
      import('../pages/verifyAccountBank/VerifyAccountBank')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate-expired',
    exact: true,
    private: true,
    component: lazy(() =>
      import('../pages/affiliateExpiredVerification/AffiliateExpiredVerification')
    ),
    fallback: Fallback,
  },
  {
    path: '/affiliate-article/:articleUri',
    exact: true,
    private: true,
    component: lazy(() => import('../pages/affiliateArticle/AffiliateArticle')),
    fallback: Fallback,
  },
  {
    path: '/500',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/handler/Error500')),
    fallback: Fallback,
  },
  {
    path: '/408',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/handler/Error408')),
    fallback: Fallback,
  },
  {
    path: '/404',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/handler/Error404')),
    fallback: Fallback,
  },
  {
    path: '*',
    exact: true,
    private: false,
    component: lazy(() => import('../pages/handler/Error404')),
    fallback: Fallback,
  },
];
