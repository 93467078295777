import { combineReducers, createStore } from 'redux';
/* user  */
import { UserStateType } from './user/type';
import { userReducer } from './user/reducer';
//import { searchResultReducer } from './search/reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { ResultState } from './search/type';
import { ProductStateType } from './product/type';
import { CartStateType } from './cart/type';
import { productReducer } from './product/reducer';
import { cartReducer } from './cart/reducer';
import { VoucherStateType } from './voucher/type';
import { voucherReducer } from './voucher/reducer';

const persistConfig = {
  key: 'root',
  storage,
};

export interface StoreStateType {
  user: UserStateType;
  search: ResultState;
  product: ProductStateType;
  cart: CartStateType;
  voucher: VoucherStateType;
  //   search: ResultState;
}

const rootReducer = combineReducers({
  user: userReducer,
  product: productReducer,
  cart: cartReducer,
  voucher: voucherReducer,
  //search: searchResultReducer
});

export type AppState = ReturnType<typeof rootReducer>;

// if (process.env.NODE_ENV === 'development') {
//   const { logger } = require(`redux-logger`);
//   middlewares.push(logger);
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function() {
  //   const enhancer = compose(applyMiddleware(...middlewares));
  const store = createStore(persistedReducer);
  console.log(store.getState());
  // @ts-ignore
  const persistor = persistStore(store);
  return { store, persistor };
}
